<template>
  <v-dialog v-model="visible" max-width="600" scrollable persistent>
    <v-card>
      <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
        <v-form @submit.prevent="handleSubmit(emitValue)">
          <v-card-title class="justify-center">{{title}}</v-card-title>
          <v-divider class="mx-4 pb-5"></v-divider>
          <v-card-text>
            <div class="row">
              <div class="swal2-icon swal2-success swal2-icon-show" style="display: flex;" v-if="success">
                <div class="swal2-success-circular-line-left" style="background-color: rgb(255, 255, 255);"></div>
                <span class="swal2-success-line-tip"></span>
                <span class="swal2-success-line-long"></span>
                <div class="swal2-success-ring"></div>
                <div class="swal2-success-fix" style="background-color: rgb(255, 255, 255);"></div>
                <div class="swal2-success-circular-line-right" style="background-color: rgb(255, 255, 255);"></div>
              </div>
              <div class="swal2-icon swal2-error swal2-icon-show" style="display: flex;" v-if="error">
                <span class="swal2-x-mark">
                  <span class="swal2-x-mark-line-left"></span>
                  <span class="swal2-x-mark-line-right"></span>
                </span>
              </div>
              <div class="swal2-icon swal2-question swal2-icon-show" style="display: flex;" v-if="type === 'confirmation'">
                <div class="swal2-icon-content">?</div>
              </div>
              <div class="col-12 text-center pt-5" v-if="type !== 'prompt'">{{text}}</div>
              <div class="col-12" v-if="type === 'prompt'">
                <ValidationProvider :rules="validationRules" :name="inputLabel" v-slot="{ errors, valid }">
                  <v-text-field v-model="inputModel" :label="inputLabel" counter="27" required dense :error-messages="errors" :success="valid"></v-text-field>
                </ValidationProvider>
              </div>
            </div>
          </v-card-text>
          <v-divider class="mx-4 mt-5"></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red darken-1" text v-if="type !== 'alert'" @click="visible = false">{{cancelText}}</v-btn>
            <v-btn color="green darken-1" type="submit" text>{{okText}}</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-form>
        </ValidationObserver>
      </v-card>
  </v-dialog>
</template>
<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
export default {
  name: 'GenericPrompt',
  components: {
    ValidationProvider, ValidationObserver
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    text: String,
    type: {
      type: String,
      default: 'alert'
    },
    success: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    },
    cancelText: {
      type: String,
      default: 'Cancel'
    },
    okText: {
      type: String,
      default: 'Ok'
    },
    inputLabel: {
      type: String,
      default: 'Enter your value'
    },
    inputType: {
      type: String,
      default: 'text'
    },
    inputValue: {
      type: String,
      default: ''
    },
    validationRules: {
      type: String,
      default: ''
    }
  },
  computed: {
    visible: {
      get: function() {
        return this.isVisible
      },
      set: function(v) {
        this.isVisible = false
        this.$nextTick(() => {
          this.$emit('cancelled')
        })
      }
    }
  },
  data: () => ({
    inputModel: '',
    isVisible: true
  }),
  mounted() {
    this.inputModel = this.inputValue
  },
  methods: {
    emitValue() {
      this.isVisible = false
      this.$nextTick(() => {
        this.$emit('confirmed', this.inputModel)
      })
    }
  },
  watch: {
    isVisible: function(newValue, oldValue) {
    }
  }
}
</script>
<style lang="scss" scoped>

</style>
