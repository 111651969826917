import Vue from 'vue'
import vuetify from './plugins/vuetify'
import clonedeep from 'lodash.clonedeep'
import GenericPrompt from '@/components/GenericPrompt.vue'
import GenericNotification from '@/components/GenericNotification.vue'
import FingerprintJS from '@fingerprintjs/fingerprintjs'
const fpPromise = FingerprintJS.load({
  monitoring: false
})
Vue.mixin({
  methods: {
    getFingerprint() {
      return fpPromise.then(fp => fp.get())
    },
    getChartColor(index) {
      const colors = [
        '#196CFE',
        '#FE80AB',
        '#FD403C',
        '#FFC638',
        '#00DA91',
        '#969CF4',
        '#CB660A',
        '#196CFE'
      ]
      if (index >= colors.length) {
        let newIndex = index - colors.length
        while (newIndex >= colors.length) {
          newIndex = newIndex - colors.length
        }
        return newIndex
      }
      return colors[index]
    },
    showErrorNotification(message) {
      const config = {
        title: message,
        type: 'error'
      }
      return this.showNotification(config)
    },
    showSuccessNotification(message) {
      const config = {
        title: message,
        type: 'success'
      }
      return this.showNotification(config)
    },
    showSuccessDialog(text) {
      const config = {
        text: text,
        success: true
      }
      return this.showDialog(config)
    },
    showErrorDialog(text) {
      const config = {
        text: text,
        error: true
      }
      return this.showDialog(config)
    },
    showTextPromptDialog(title, label, rules, value = '') {
      const config = {
        type: 'prompt',
        title: title,
        inputValue: value,
        inputLabel: label,
        validationRules: rules
      }
      return this.showDialog(config)
    },
    showConfirmationDialog(message) {
      const config = {
        type: 'confirmation',
        text: message
      }
      return this.showDialog(config)
    },
    showDialog(config) {
      const Component = Vue.extend(GenericPrompt)
      const instance = new Component({
        vuetify,
        propsData: {
          ...config
        }
      })
      instance.$mount()
      this.$el.appendChild(instance.$el)
      return new Promise((resolve, reject) => {
        instance.$on('confirmed', r => {
          resolve(r)
          instance.$destroy()
        })
        instance.$on('cancelled', r => {
          reject(new Error('cancelled', r))
          instance.$destroy()
        })
      })
    },
    showNotification(config) {
      const Component = Vue.extend(GenericNotification)
      const instance = new Component({
        vuetify,
        propsData: {
          ...config
        }
      })
      instance.$mount()
      this.$root.$el.appendChild(instance.$el)
      return new Promise((resolve, reject) => {
        instance.$on('hidden', r => {
          resolve()
          instance.$destroy()
        })
      })
    },
    clone(target) {
      return clonedeep(target)
    },
    downloadFile(fileName, dataBlob) {
      var fileURL = window.URL.createObjectURL(dataBlob)
      var fileLink = document.createElement('a')

      fileLink.href = fileURL
      fileLink.setAttribute('download', fileName)
      document.body.appendChild(fileLink)

      fileLink.click()
      fileLink.remove()
    },
    addRequestParams(config) {
      var params = '?'
      var keys = Object.keys(config)
      var i = 0
      var length = keys.length
      for (i; i < length; i++) {
        var name = keys[i]
        if (config[name] != null) {
          params += name + '=' + encodeURIComponent(config[name])
        }
        if (i + 1 < length) {
          params += '&'
        }
      }
      return params
    }
  }
})
