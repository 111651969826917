/* eslint-disable no-template-curly-in-string */
/* eslint-disable camelcase */
import { required, confirmed, length, email, min, max, min_value, max_value, alpha_dash, regex, size, numeric, double } from 'vee-validate/dist/rules'
import { extend, setInteractionMode } from 'vee-validate'

// extend the vee-validate rules and provide a custom error message for each one
setInteractionMode('lazy')

extend('regex', regex)
extend('required', required)
extend('numeric', numeric)
extend('email', email)
extend('confirmed', confirmed)
extend('length', length)
extend('min_value', min_value)
extend('max', max)
extend('max_value', max_value)
extend('alpha_dash', alpha_dash)
extend('double', double)
extend('size', {
  ...size,
  message: (field, params, data) => {
    return `File size must be max: ${params.size}Kb`
  }
})
extend('min', {
  ...min,
  message: (field, params, data) => {
    return `Please enter at least ${params.length} characters`
  }
})
extend('required', {
  ...required,
  message: (field, params, data) => {
    return `${field} is required`
  }
})
extend('ip', {
  validate: function(value, args) {
    return /^((?:[0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])[.]){3}(?:[0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])$/.test(value)
  },
  message: (field, params, data) => {
    return 'Ip address is not valid'
  }
})
