import LocalStorage from '@/plugins/local-storage.js'
import { logout } from '@/api/backend.js'
import router from '@/router'

export default {
  namespaced: true,
  state: {
    currentUser: null
  },
  getters: {
    apiToken(state) {
      if (state.currentUser != null) {
        return state.currentUser.id
      }
      return null
    }
  },
  mutations: {
    logout(state) {
      state.currentUser = null
      LocalStorage.remove('current-user')
      this._vm.$disconnect()
    },
    login(state, currentUser) {
      state.currentUser = currentUser
      this._vm.$connect(state.currentUser.id)
      LocalStorage.put('current-user', currentUser)
    },
    loadLocalStorage(state) {
      const currentUser = LocalStorage.get('current-user')
      if (currentUser !== null) {
        state.currentUser = currentUser
        this._vm.$connect(state.currentUser.id)
      }
    }
  },
  actions: {
    async logout(context) {
      return logout()
        .catch(() => {})
        .then(() => context.commit('logout'))
        .then(() => router.push('Login'))
    },
    login(context, currentUser) {
      context.commit('login', currentUser)
    }
  }
}
