import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import store from '@/store'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: () => ({ left: 0, top: 0 }),
  routes
})
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.authenticationRequired)) {
    if (store.getters['security/apiToken'] === null) {
      next({ name: 'Login' })
    } else {
      next()
    }
  } else {
    next()
  }
})
export default router
