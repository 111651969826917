import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import WebSocket from '@/plugins/websocket.js'
import VueFullscreen from 'vue-fullscreen'

import './vee-validate'
import './mixins'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
import 'sweetalert2/dist/sweetalert2.min.css'

Vue.config.productionTip = false

Vue.use(WebSocket, {
  url: `${process.env.VUE_APP_API_URL}/client-connect`,
  pingTimeout: 1000 * 30,
  pongTimeout: 1000 * 30,
  reconnectTimeout: 1000 * 15,
  pingMessage: 'heartbeat',
  store: store
})
Vue.use(VueFullscreen)
store.commit('loadLocalStorage')
new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App)
}).$mount('#app')
