import api from '@/plugins/axios.js'

export function logout() {
  return api.post('/logout')
}
export function login(username, password) {
  return api.post('/login', {
    username: username,
    password: password
  })
}

export function findForms() {
  return api.get('/forms')
}
export function findForm(id) {
  return api.get(`/forms/public/${id}`)
}
export function deleteForm(id) {
  return api.delete(`/forms/${id}`)
}
export function saveForm(form) {
  return api.post('/forms', form)
}
export function openForm(id) {
  return api.put(`/forms/${id}/open`)
}
export function closeForm(id) {
  return api.put(`/forms/${id}/close`)
}
export function clearAnswers(id) {
  return api.put(`/forms/${id}/clear`)
}
export function getQuestionAnswers(id) {
  return api.get(`/forms/questions/${id}/answers`)
}
export function answerQuestion(formId, questionId, option, identifier) {
  return api.put(`/forms/public/${formId}/question/${questionId}`, option, {
    headers: {
      'Content-Type': 'text/plain',
      'X-Client-Identifier': identifier
    }
  })
}
export function removeAnswer(id) {
  return api.delete(`/forms/public/answers/${id}/remove`)
}
export function getAnsweredQuestions(formId, identifier) {
  return api.get(`/forms/public/${formId}/answers`, {
    headers: {
      'X-Client-Identifier': identifier
    }
  })
}
