import Vue from 'vue'
import Vuex from 'vuex'
import security from './security'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {
    loadLocalStorage(state) {
      this.commit('security/loadLocalStorage')
    },
    logout(state) {
      this.dispatch('security/logout')
    }
  },
  actions: {},
  modules: { security }
})
