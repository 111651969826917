const routes = [
  {
    path: '/',
    name: 'AdminLayout',
    component: () => import('@/layouts/AdminLayout.vue'),
    meta: { authenticationRequired: true },
    children: [
      {
        name: 'Home',
        path: '/home',
        meta: { authenticationRequired: true },
        component: () => import('@/components/Home.vue')
      },
      {
        name: 'FormDetails',
        path: '/create-form',
        meta: { authenticationRequired: true },
        component: () => import('@/components/FormDetails.vue')
      }
    ]
  },
  {
    name: 'Login',
    path: '/login',
    meta: {},
    component: () => import('@/pages/Login.vue')
  },
  {
    name: 'FormView',
    path: '/form/:id',
    meta: {},
    component: () => import('@/components/FormView.vue')
  },
  {
    path: '/:catchAll(.*)*',
    component: () => import('@/pages/Error404.vue')
  }
]

export default routes
